import './Map.scss';

import React from 'react';

import * as Graphic from './MapGraphic';

export default class RoadmapMap extends React.Component {
  render() {
    return (
      <div className="RoadmapMap">
        <div className="RoadmapMap-entry">
          <div className="RoadmapMap-graphic">
            <Graphic.One />
          </div>
          <div className="RoadmapMap-entryContent">
            <h2 className="RoadmapMap-entryTitle">Where We Started</h2>
            <p>
              Explore SEL is a field beset by dilemmas about how to best measure
              and promote skills. Often different and sometimes conflicting
              terminology is used to define and describe skills.
            </p>
          </div>
        </div>

        <div className="RoadmapMap-entry">
          <div className="RoadmapMap-graphic">
            <Graphic.Two />
          </div>
          <div className="RoadmapMap-entryContent">
            <h2 className="RoadmapMap-entryTitle">Where We Are</h2>
            <p>
              We created three visual tools to identify the focus of each
              framework, show where terms in one framework relate to terms in
              another framework, and to see where particular skills appear
              across frameworks. We also compiled an index of framework profiles
              with data consistently formatted between them for easy comparison
              and built a thesaurus for SEL terms.
            </p>
          </div>
        </div>

        <div className="RoadmapMap-entry">
          <div className="RoadmapMap-graphic">
            <Graphic.Three />
          </div>
          <div className="RoadmapMap-entryContent">
            <h2 className="RoadmapMap-entryTitle">Where We&rsquo;re Going</h2>
            <p>
              In the future, we&rsquo;ll be adding more frameworks to our data
              set and improving our measurement tools. We also have planned
              enhancements to the visualizations and terms thesaurus to make
              them more useful.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
