import './ChangeLog.scss';

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

type Props = {
  changes: {
    date: string;
    changes: string[];
  }[];
};

const RoadmapChangeLog: React.FC<Props> = (props) => {
  const { changes } = props;

  return (
    <div>
      <h2>Past Updates</h2>

      {changes.map((changeSet, index) => (
        <div className="RoadmapChangeLog-entry" key={index}>
          <h3 className="RoadmapChangeLog-date">{changeSet.date}</h3>
          <div className="RoadmapChangeLog-content">
            <ul>
              {changeSet.changes.map((change, index) => (
                <li key={index}>{change}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default function RoadmapChangeLogQuery() {
  return (
    <StaticQuery
      query={graphql`
        query ChangelogQuery {
          changelog: allChangelogYaml {
            changes: nodes {
              date
              changes
            }
          }
        }
      `}
      render={(data) => {
        const { changes } = data.changelog;

        return <RoadmapChangeLog changes={changes} />;
      }}
    />
  );
}
