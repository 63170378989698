import './roadmap.scss';

import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/layout/SplitLayout';
import Sidebar from 'components/layout/Sidebar';
import Map from 'components/roadmap/Map';
import Changelog from 'components/roadmap/Changelog';

export default class RoadmapPage extends React.Component {
  render() {
    return (
      <Layout
        sidebar={
          <Sidebar>
            <div className="RoadmapPage-sidebar">
              <p>
                Questions or comments about what we&rsquo;re working on?
                We&rsquo;d love your feedback.
              </p>

              <p>
                Let us know how you use SEL or how our research could help you:
              </p>

              <Link className="RoadmapPage-surveyButton" to="/feedback/">
                Fill out a short survey
              </Link>
            </div>
          </Sidebar>
        }
      >
        <div className="RoadmapPage">
          <div className="RoadmapPage-intro">
            <h1>Roadmap</h1>
            <p className="emphasis">
              Explore SEL is an ongoing project. We&rsquo;re constantly doing
              new research to expand our database and designing and developing
              new features for the site.
            </p>
          </div>

          <Map />

          <Changelog />
        </div>
      </Layout>
    );
  }
}
