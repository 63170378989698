import React from 'react';

const One: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 300">
      <circle fill="#e0e2e4" cx="8" cy="12" r="8" />
      <circle fill="#236caa" cx="8" cy="12" r="4.71" />

      <rect fill="#236caa" x="7" y="10" width="2" height="322" />
    </svg>
  );
};

const Two: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 300">
      <circle fill="#e0e2e4" cx="8" cy="12" r="8" />
      <circle fill="#236caa" cx="8" cy="12" r="4.71" />

      <rect fill="#236caa" x="7" y="-30" width="2" height="330" />
    </svg>
  );
};

const Three: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 30">
      <g transform="translate(0, -10)">
        <rect fill="#236caa" x="7" y="-295.89" width="2" height="322" />

        <rect
          fill="#236caa"
          x="4.88"
          y="21.24"
          width="2"
          height="8"
          transform="translate(-16.12 11.55) rotate(-45)"
        />
        <rect
          fill="#236caa"
          x="6.12"
          y="24.24"
          width="8"
          height="2"
          transform="translate(-14.88 14.55) rotate(-45)"
        />
      </g>
    </svg>
  );
};

export { One, Two, Three };
